import { Box, Paper } from "@mui/material";
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";
import React, { useEffect } from "react";

function Board({ boardData, sheetId, customerToken }) {
  // const [dashboard, setDashboard] = React.useState(false);

  const loadDashboard = async (boardData, customerToken) => {
    if (boardData === "" || boardData === undefined) {
      return;
    }

    if (sheetId === "" || sheetId === undefined) {
      return;
    }

    let embeddedDashboardExperience;

    const embeddingContext = await createEmbeddingContext();
    const { embedDashboard } = embeddingContext;
    const containerDiv = document.getElementById("dashboardContainer");
    containerDiv.innerHTML = "";

    const options = {
      parameters: [
        {
          Name: 'paramCustomerCode',
          Values: [
            customerToken?.ParamCustomerCode ?? 'SilverState'
          ],
        },
        {
          Name: 'paramL1Label',
          Values: [
            customerToken?.ParamL1Label ?? 'Level1'
          ],
        },
        {
          Name: 'paramL2Label',
          Values: [
            customerToken?.ParamL2Label ?? 'Level2'
          ],
        },
        {
          Name: 'paramL3Label',
          Values: [
            customerToken?.ParamL3Label ?? 'Level3'
          ],
        },
        {
          Name: 'paramL4Label',
          Values: [
            customerToken?.ParamL4Label ?? 'Level4'
          ],
        },{
          Name: 'paramL5Label',
          Values: [
            customerToken?.ParamL5Label ?? 'Level5'
          ],
        }
      ],
      sheetOptions: {
        initialSheetId: sheetId,
        singleSheet: true,
        emitSizeChangedEventOnSheetChange: true,
      },
      toolbarOptions: {
        export: true,
        undoRedo: true,
        reset: true,
        bookmarks: true,
      },
      onMessage: async (messageEvent, experienceMetadata) => {
        switch (messageEvent.eventName) {
          case "PARAMETERS_CHANGED": {
            console.log(
              "Parameters changed. Changed parameters:",
              messageEvent.message.changedParameters
            );
            embeddedDashboardExperience.getParameters().then((parameters) => {
              console.log(
                "Fetched parameters from source dashboard:",
                parameters
              );
              window.sourceDashboardParameters = parameters;
            });
            break;
          }
        }
      },
    };

    const frameOptions = {
      url: boardData,
      container: containerDiv,
      resizeHeightOnSizeChangedEvent: true,
      onChange: (changeEvent, metadata) => {
        // eslint-disable-next-line default-case
        switch (changeEvent.eventName) {
          case "FRAME_MOUNTED": {
            console.log("Do something when the experience frame is mounted.");
            break;
          }
          case "FRAME_LOADED": {
            console.log("Do something when the experience frame is loaded.");
            break;
          }
        }
      },
    };

    if (window.sourceDashboardParameters) {
      options.parameters = window.sourceDashboardParameters;
    }

    try {
      embeddedDashboardExperience = await embedDashboard(frameOptions, options);


      embeddedDashboardExperience
        .setParameters(window.sourceDashboardParameters)
        .catch((error) => {
          console.error("Error setting parameters:", error);
        });


    } catch (error) {
      console.error("Error embedding dashboard:", error);
    }
  };

  useEffect(() => {
    console.log("customerToken", customerToken);
    if (boardData !== "" && boardData !== undefined) {
      //setDashboard(true);
      console.log("boardData::::>>>>", sheetId);
      loadDashboard(boardData, customerToken);
    }
  }, [boardData, sheetId, customerToken]);

  return (
    <div>
      <Box
        sx={{
          p: 1,
          // backgroundColor: "grey",
        }}
      >
        <Paper elevation={1} sx={{ padding: "0.5%", borderRadius: "5px" }}>
          <div id="dashboardContainer"></div>
        </Paper>
      </Box>
    </div>
  );
}

export default Board;
