import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Select,
  MenuItem,
  Button,
  Box,
  Paper,
  Typography,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { primaryColor1, whiteColor, redColor } from "../../commonStyles/styles";
import { currentUserData, selectBiLogin } from "../../user/LoginSlice";
import { getApi, postApi } from "../../api/Api";
import { routeName } from "../../commonStrings/CommonStrings";
import { jsonTransform } from "../helpers/UserListHelper";
import { jsonTransformGroup } from "../helpers/GroupNameHelper";
import { jsonTransformCustomerCode } from "../helpers/CustomerCodeHelper";
import axios from "axios";
import {
  jsonTransformLevel1,
  jsonTransformLevel2,
  jsonTransformLevel3,
  jsonTransformLevel4,
  jsonTransformLevel5,
} from "../helpers/levelsHelper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddRowLevelSecurity({ openAdd, onClose, getTableDetails }) {
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    userName: "",
    groupName: "",
    // emailId: "",
    customerCode: "",
    level1: [],
    level2: [],
    level3: [],
    level4: [],
    level5: [],
  });
  const [formDataArr, setFormDataArr] = useState([]);
  const [userList, setUserList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [customerCode, setCustomerCode] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const [level1Arr, setLevel1Arr] = useState([]);
  const [level2Arr, setLevel2Arr] = useState([]);
  const [level3Arr, setLevel3Arr] = useState([]);
  const [level4Arr, setLevel4Arr] = useState([]);
  const [level5Arr, setLevel5Arr] = useState([]);

  const biUserData = useSelector(selectBiLogin);
  const userData = useSelector(currentUserData);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (isChecked === false) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        level1: [],
        level2: [],
        level3: [],
        level4: [],
        level5: [],
      }));
    } else {
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (
      name === "level1" ||
      name === "level2" ||
      name === "level3" ||
      name === "level4" ||
      name === "level5"
    ) {
      // If the name is "multiSelect", update the array of selected values

      const selectedValues = Array.isArray(value) ? value : [value];
      setFormData({
        ...formData,
        [name]: selectedValues,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (name === "groupName") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        userName: "", // Update the userName property
      }));
    }
    if (name === "customerCode" && value !== "") {
      getLevel1(value);
      setIsChecked(false);
      setFormData((prevFormData) => ({
        ...prevFormData,
        level1: [],
        level2: [],
        level3: [],
        level4: [],
        level5: [],
      }));
    } else if (name === "level1" && value?.length > 0) {
      getLevel2(value);
      setFormData((prevFormData) => ({
        ...prevFormData,

        level2: [],
        level3: [],
        level4: [],
        level5: [],
      }));
    } else if (name === "level2" && value?.length > 0) {
      getLevel3(value);
      setFormData((prevFormData) => ({
        ...prevFormData,

        level3: [],
        level4: [],
        level5: [],
      }));
    } else if (name === "level3" && value?.length > 0) {
      getLevel4(value);
      setFormData((prevFormData) => ({
        ...prevFormData,

        level4: [],
        level5: [],
      }));
    } else if (name === "level4" && value?.length > 0) {
      getLevel5(value);
      setFormData((prevFormData) => ({
        ...prevFormData,

        level5: [],
      }));
    }

    // if()
  };

  const resetAll = () => {
    setIsChecked(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      userName: "",
      groupName: "",
      customerCode: "",
      level1: [],
      level2: [],
      level3: [],
      level4: [],
      level5: [],
    }));
  };

  const isFormValid = () => {
    if (isChecked === true) {
      return true;
    } else {
      return (
        formData.groupName !== "" &&
        formData.customerCode !== "" &&
        formData.level1.length > 0 &&
        formData.level2.length > 0 &&
        formData.level3.length > 0 &&
        formData.level4.length > 0 &&
        formData.level5.length > 0
      );
    }
  };

  const getLevel1 = (val) => {
    const reqBody = {
      customerCode: val,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/security/row/list/memberL1`,
        reqBody
      )
      .then((res) => {
        console.log(res);
        var newLevel1Arr = jsonTransformLevel1(res?.data?.data);

        setLevel1Arr(newLevel1Arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLevel2 = (val) => {
    console.log(formData.customerCode);
    console.log(val);
    const reqBody = {
      customerCode: formData.customerCode,
      memberL1: val,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/security/row/list/memberL2`,
        reqBody
      )
      .then((res) => {
        console.log(res);
        var newLevel2Arr = jsonTransformLevel2(res?.data?.data);
        setLevel2Arr(newLevel2Arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getLevel3 = (val) => {
    const level1ReqBody = formData.level1.map((description) => {
      const parts = description.split(" > ");
      return parts[0];
    });

    const level2ReqBody = val.map((description) => {
      const parts = description.split(" > ");
      return parts[0];
    });

    const reqBody = {
      customerCode: formData.customerCode,
      memberL1: level1ReqBody,
      memberL2: level2ReqBody,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/security/row/list/memberL3`,
        reqBody
      )
      .then((res) => {
        console.log(res);
        var newLevel3Arr = jsonTransformLevel3(res?.data?.data);
        setLevel3Arr(newLevel3Arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getLevel4 = (val) => {
    const level1ReqBody = formData.level1.map((description) => {
      const parts = description.split(" > ");
      return parts[0];
    });

    const level2ReqBody = formData.level2.map((description) => {
      const parts = description.split(" > ");
      return parts[0];
    });

    const level3ReqBody = val.map((description) => {
      const parts = description.split(" > ");
      return parts[0];
    });
    console.log(level3ReqBody);
    const reqBody = {
      customerCode: formData.customerCode,
      memberL1: level1ReqBody,
      memberL2: level2ReqBody,
      memberL3: level3ReqBody,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/security/row/list/memberL4`,
        reqBody
      )
      .then((res) => {
        console.log(res);
        var newLevel4Arr = jsonTransformLevel4(res?.data?.data);
        setLevel4Arr(newLevel4Arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getLevel5 = (val) => {
    const level1ReqBody = formData.level1.map((description) => {
      const parts = description.split(" > ");
      return parts[0];
    });

    const level2ReqBody = formData.level2.map((description) => {
      const parts = description.split(" > ");
      return parts[0];
    });

    const level3ReqBody = formData.level3.map((description) => {
      const parts = description.split(" > ");
      return parts[0];
    });
    const level4ReqBody = val.map((description) => {
      const parts = description.split(" > ");
      return parts[0];
    });
    const reqBody = {
      customerCode: formData.customerCode,
      memberL1: level1ReqBody,
      memberL2: level2ReqBody,
      memberL3: level3ReqBody,
      memberL4: level4ReqBody,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/security/row/list/memberL5`,
        reqBody
      )
      .then((res) => {
        console.log(res);
        var newLevel5Arr = jsonTransformLevel5(res?.data?.data);
        setLevel5Arr(newLevel5Arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSave = () => {
    console.log(formData);
    var reqBody = formData;

    if (isChecked) {
      const modifiedObject = { ...formData };

      // Step 2: Modify the 'level1' array
      if (modifiedObject.userName === "") {
        modifiedObject.userName = "null";
      }

      modifiedObject.level5.push("null > null > null > null > null");
      reqBody = modifiedObject;
    } else {
      const modifiedObject = { ...formData };

      // Step 2: Modify the 'level1' array
      if (modifiedObject.userName === "") {
        modifiedObject.userName = "null";
      }

      reqBody = modifiedObject;
    }
    console.log(reqBody);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/security/row/create`, reqBody)
      .then((res) => {
        console.log(res);

        resetAll();
        getTableDetails();
        toast("Added row level security successfully", {
          type: "success",
          theme: "dark",
          autoClose: 2000,
        });
        onClose();
      })
      .catch((err) => {
        console.log(err);
        toast("Some error occured", {
          type: "error",
          theme: "dark",
          autoClose: 2000,
        });
      });

    // const updatedFormDataArr = [...formDataArr, formData];
    // setFormDataArr(updatedFormDataArr);
    // setFormData({
    //   userName: "",
    //   groupName: "",
    //   // emailId: "",
    //   customerCode: "",
    //   level1: [],
    //   level2: [],
    //   level3: [],
    //   level4: [],
    //   level5: [],
    // });
  };

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
      // "AnotherHeader": "Value"
    };

    getApi(
      "/quickSight/userList",
      {},
      accessToken.AccessToken,
      organizationId,
      headers
    )
      .then((res) => {
        // setUserList(res.data.UserList);
        const newRes = jsonTransform(res.data.UserList);

        setUserList(newRes);
        // setGroupUserList(newRes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/quickSight/groupList`, {
        headers: headers,
      })
      .then((response) => {
        // setGroupList(response.data.data.GroupList);
        const newGroupRes = jsonTransformGroup(response.data.data.GroupList);
        setGroupList(newGroupRes);
      })
      .catch((error) => {
        console.error("Error:", error.response);
      });

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/security/row/list/customerCode`)
      .then((res) => {
        const newCustomerCode = jsonTransformCustomerCode(res?.data?.data);
        setCustomerCode(newCustomerCode);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Dialog
        open={openAdd}
        onClose={onClose}
        maxWidth="xl" // Set the maximum width to medium
        fullWidth // Make the dialog take the full width of the viewport
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        PaperProps={{
          sx: {
            width: "80vw", // Set the desired width, e.g., 80% of the screen width
            // height: "40%", // Set the desired height, e.g., 80% of the screen height
            borderRadius: "5px", // Add border radius if needed
          },
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>Add Row level security</DialogTitle>
          <DialogTitle>
            <CloseIcon onClick={onClose} className="pointerCursor" />
          </DialogTitle>
        </div>

        <DialogContent
          sx={{
            minHeight: "300px", // Set a minimum height
            minWidth: "500px", // Set a minimum width
            p: 3,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <FormControl
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              >
                <InputLabel id="groupName-label">Group name</InputLabel>
                <Select
                  labelId="groupName"
                  id="groupName"
                  value={formData.groupName}
                  label="Group Name"
                  name="groupName"
                  onChange={handleInputChange}
                  MenuProps={{
                    PaperProps: { sx: { maxHeight: 350, overflowY: "auto" } },
                  }}
                >
                  {groupList.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <FormControl
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              >
                <InputLabel id="username-label">Username</InputLabel>
                <Select
                  labelId="username"
                  id="userName"
                  value={formData.userName}
                  label="Username"
                  name="userName"
                  onChange={handleInputChange}
                  MenuProps={{
                    PaperProps: { sx: { maxHeight: 350, overflowY: "auto" } },
                  }}
                  disabled={formData.groupName}
                >
                  {userList.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <FormControl
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              >
                <InputLabel id="groupName-label">Customer Code</InputLabel>
                <Select
                  labelId="Customer Code"
                  id="customerCode"
                  value={formData.customerCode}
                  label="Customer Code"
                  name="customerCode"
                  onChange={handleInputChange}
                  MenuProps={{
                    PaperProps: { sx: { maxHeight: 350, overflowY: "auto" } },
                  }}
                >
                  {customerCode.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {formData.customerCode && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {/* <FormControl
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              >
               
              </FormControl> */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        color="primary"
                      />
                    }
                    label={isChecked ? "No restrictions" : "No restrictions "}
                  />
                </Grid>
              </>
            )}
            {formData.customerCode && !isChecked && (
              <>
                <Grid item xs={12} sm={6}>
                  {/* Multi-Select Dropdown */}
                  <FormControl
                    fullWidth
                    size="small"
                    margin="dense"
                    variant="outlined"
                  >
                    <InputLabel id="level1-label">Level 1</InputLabel>
                    <Select
                      labelId="level1-label"
                      id="level1"
                      multiple
                      value={formData.level1}
                      label="Multi-Select"
                      name="level1"
                      onChange={handleInputChange}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={{
                        PaperProps: {
                          sx: { maxHeight: 250, overflowY: "auto" },
                        },
                      }}
                    >
                      {level1Arr.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          <Checkbox
                            checked={formData.level1.indexOf(item.value) > -1}
                          />
                          <ListItemText primary={item.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {formData.level1.length > 0 && (
                  <Grid item xs={12} sm={6}>
                    {/* Second Partition */}
                    <FormControl
                      fullWidth
                      size="small"
                      margin="dense"
                      variant="outlined"
                    >
                      <InputLabel id="level2-label">Level 2</InputLabel>
                      <Select
                        labelId="level2-label"
                        id="level2"
                        multiple
                        value={formData.level2}
                        label="Multi-Select"
                        name="level2"
                        onChange={handleInputChange}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={{
                          PaperProps: {
                            sx: { maxHeight: 350, overflowY: "auto" },
                          },
                        }}
                      >
                        {level2Arr.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            <Checkbox
                              checked={formData.level2.indexOf(item.value) > -1}
                            />
                            <ListItemText primary={item.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {formData.level2.length > 0 && (
                  <Grid item xs={12} sm={6}>
                    {/* Second Partition */}
                    <FormControl
                      fullWidth
                      size="small"
                      margin="dense"
                      variant="outlined"
                    >
                      <InputLabel id="level3-label">Level 3</InputLabel>
                      <Select
                        labelId="level3-label"
                        id="level3"
                        multiple
                        value={formData.level3}
                        label="Multi-Select"
                        name="level3"
                        onChange={handleInputChange}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={{
                          PaperProps: {
                            sx: { maxHeight: 350, overflowY: "auto" },
                          },
                        }}
                      >
                        {level3Arr.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            <Checkbox
                              checked={formData.level3.indexOf(item.value) > -1}
                            />
                            <ListItemText primary={item.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {formData.level3.length > 0 && (
                  <Grid item xs={12} sm={6}>
                    {/* Second Partition */}
                    <FormControl
                      fullWidth
                      size="small"
                      margin="dense"
                      variant="outlined"
                    >
                      <InputLabel id="level4-label">Level 4</InputLabel>
                      <Select
                        labelId="level4-label"
                        id="level4"
                        multiple
                        value={formData.level4}
                        label="Multi-Select"
                        name="level4"
                        onChange={handleInputChange}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={{
                          PaperProps: {
                            sx: { maxHeight: 350, overflowY: "auto" },
                          },
                        }}
                      >
                        {level4Arr.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            <Checkbox
                              checked={formData.level4.indexOf(item.value) > -1}
                            />
                            <ListItemText primary={item.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {formData.level4.length > 0 && (
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      size="small"
                      margin="dense"
                      variant="outlined"
                    >
                      <InputLabel id="level5-label">Level 5</InputLabel>
                      <Select
                        labelId="level5-label"
                        id="level5"
                        multiple
                        value={formData.level5}
                        label="Multi-Select"
                        name="level5"
                        onChange={handleInputChange}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={{
                          PaperProps: {
                            sx: { maxHeight: 350, overflowY: "auto" },
                          },
                        }}
                      >
                        {level5Arr.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            <Checkbox
                              checked={formData.level5.indexOf(item.value) > -1}
                            />
                            <ListItemText primary={item.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 3,
          }}
        >
          {loader ? (
            <div className="loader"></div>
          ) : (
            <>
              <Button
                disabled={!isFormValid()}
                variant="contained"
                sx={{ backgroundColor: primaryColor1 }}
                onClick={handleSave}
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: whiteColor,
                  color: primaryColor1,
                  borderColor: primaryColor1,
                }}
                onClick={resetAll}
              >
                Reset
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </div>
  );
}

export default AddRowLevelSecurity;
