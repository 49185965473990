// persistConfig.js

import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const persistConfig = {
  key: "root", // key under which your persisted data will be stored in localStorage
  storage, // specify the storage engine (localStorage)
  // Optionally, you can configure other options such as blacklist or whitelist
  // For example, to blacklist certain reducers from being persisted:
  // blacklist: ["reducerToExclude"],
};

export default persistConfig;
