import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  Button,
  Box,
  Paper,
  Typography,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { primaryColor1, whiteColor, redColor } from "../../commonStyles/styles";
import { DataGrid } from "@mui/x-data-grid";
import SaveIcon from "../../../assets/save.png";
import EditTableIcon from "../../../assets/editTable.png";
import AddIcon from "@mui/icons-material/Add";
import "../loader.scss";
import CloseIcon from "@mui/icons-material/Close";
import AddRowLevelSecurity from "./AddRowLevelSecurity";
import axios from "axios";
// function RenderCell({ value, colDef }) {
//   const [selectedRole, setSelectedRole] = useState(value);

//   const handleRoleChange = (event) => {
//     setSelectedRole(event.target.value);
//   };

//   return (
//     <Select
//       value={selectedRole}
//       onChange={handleRoleChange}
//       style={{ width: "100%", height: "60%" }}
//     >
//       {colDef.valueOptions.map((option) => (
//         <MenuItem key={option.value} value={option.value}>
//           {option.label}
//         </MenuItem>
//       ))}
//     </Select>
//   );
// }

function RowLevelSecurity() {
  const [searchQuery, setSearchQuery] = useState("");
  const [openAdd, setOpenAdd] = useState(false);
  const [rowLevelArr, setRowLevelArr] = useState([]);

  //   const [menuList, setMenuList] = useState([]);

  const menuList = [
    {
      id: 1,
      UserName: "test",
      GroupName: "group",
      CustomerCode: "code",
      Email: "e@bz.ai",
      Role: "All",
    },
    {
      id: 2,
      UserName: "test2",
      GroupName: "group",
      CustomerCode: "code",
      Email: "e@bz.ai",
      Role: "Connect",
    },
    {
      id: 3,
      UserName: "test3",
      GroupName: "group",
      CustomerCode: "code",
      Email: "e@bz.ai",
      Role: "All",
    },
  ];

  const levelArr = [
    { value: "All", label: "All" },
    { value: "Connect", label: "Connect" },
  ];

  const columns = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   headerClassName: "super-app-theme--header",
    //   // width: 150,
    //   hide: true,
    //   flex: 1,

    //   renderHeader: () => <strong>{"ID"}</strong>,
    //   // editable: true,
    // },
    {
      field: "username",
      headerName: "Username",
      headerClassName: "super-app-theme--header",
      // width: 150,
      flex: 1,
      renderHeader: () => <strong>{"Username"}</strong>,
      // editable: true,
    },
    {
      field: "groupname",
      headerName: "Group Name",
      headerClassName: "super-app-theme--header",
      // width: 150,
      flex: 1,
      renderHeader: () => <strong>{"Group Name"}</strong>,
      // editable: true,
    },
    {
      field: "customer_code",
      headerName: "Customer Code",
      headerClassName: "super-app-theme--header",
      width: 200,
      // flex: 1,
      renderHeader: () => <strong>{"Customer Code"}</strong>,
      // editable: true,
    },
    {
      field: "level1",
      headerName: "Level 1",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 1"}</strong>,
    },

    {
      field: "level2",
      headerName: "Level 2",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 2"}</strong>,
    },
    {
      field: "level3",
      headerName: "Level 3",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 3"}</strong>,
    },
    {
      field: "level4",
      headerName: "Level 4",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 4"}</strong>,
    },

    {
      field: "level5",
      headerName: "Level 5",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 5"}</strong>,
    },
  ];

  useEffect(() => {
    getTableDetails();
  }, []);

  const getTableDetails = () => {
    console.log("here");
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/security/row/list`)
      .then((res) => {
        console.log(res);
        setRowLevelArr(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClickAddOpen = () => {
    setOpenAdd(true);
  };

  const handleClickAddClose = () => {
    setOpenAdd(false);
  };
  const filterMenuList = () => {
    return rowLevelArr
      .map((item) => ({
        ...item,
        username: item.username ?? "null",
        level1: item?.level1 ?? "null",
        level2: item?.level2 ?? "null",
        level3: item?.level3 ?? "null",
        level4: item?.level4 ?? "null",
        level5: item?.level5 ?? "null",
      }))
      .filter((item) => {
        const lowercaseSearchQuery = searchQuery.toLowerCase();
        return (
          item?.username?.toLowerCase()?.includes(lowercaseSearchQuery) ||
          item?.groupname?.toLowerCase()?.includes(lowercaseSearchQuery) ||
          item?.customer_code?.toLowerCase()?.includes(lowercaseSearchQuery) ||
          item?.level1?.toLowerCase()?.includes(lowercaseSearchQuery) ||
          item?.level2?.toLowerCase()?.includes(lowercaseSearchQuery) ||
          item?.level3?.toLowerCase()?.includes(lowercaseSearchQuery) ||
          item?.level4?.toLowerCase()?.includes(lowercaseSearchQuery) ||
          item?.level5?.toLowerCase()?.includes(lowercaseSearchQuery)
        );
      });
  };

  return (
    <div>
      <Paper elevation={1} sx={{ borderRadius: "5px" }}>
        <Box
          //   sx={{ height: 400, width: "100%" }}
          sx={{
            p: 4,
            alignItems: "center",
            "& .super-app-theme--header": {
              backgroundColor: primaryColor1,
              color: whiteColor,
            },
          }}
        >
          <Typography variant="h6" className="sub-header">
            Row Level Security
          </Typography>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "2%",
            }}
          >
            <Button
              size="medium"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleClickAddOpen}
              sx={{ backgroundColor: whiteColor, color: primaryColor1 }}
            >
              Add Row Level Security
            </Button>
            <div>
              <TextField
                id="outlined-basic"
                label="Search Group"
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: <SearchIcon style={{ opacity: 0.5 }} />,
                }}
                //   sx={{ paddingRight: "20px" }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>

          <DataGrid
            rows={filterMenuList()}
            columns={columns}
            getRowId={(row) => row.customer_code}
            initialState={{
              ...menuList.initialState,
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            pageSizeOptions={[5, 10, 25]}
            disableRowSelectionOnClick
          />
        </Box>
      </Paper>
      <AddRowLevelSecurity
        openAdd={openAdd}
        onClose={handleClickAddClose}
        getTableDetails={getTableDetails}
      />
    </div>
  );
}

export default RowLevelSecurity;
