import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import "./admin.scss";
import {
  primaryColor1,
  whiteColor,
  redColor,
  buttonColorCommon,
} from "../commonStyles/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Paper,
  Typography,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import AddGroupUser from "./AddGroupUser";
import { routeName } from "../commonStrings/CommonStrings";
import {
  currentUserData,
  selectBiLogin,
  setBiLogin,
  loginUserData,
} from "../user/LoginSlice";
import axios from "axios";
import DeleteIcon from "../../assets/deleteTable.png";
import EditTableIcon from "../../assets/editTable.png";
import AddUserIcon from "../../assets/addUser.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postApi, deleteApi } from "../api/Api";
import CloseIcon from "@mui/icons-material/Close";
// function RenderCell({ value, colDef }) {
//   const [selectedRole, setSelectedRole] = useState(value);

//   const handleRoleChange = (event) => {
//     setSelectedRole(event.target.value);
//   };

//   return (
//     <Select
//       value={selectedRole}
//       onChange={handleRoleChange}
//       style={{ width: "100%", height: "80%" }}
//     >
//       {colDef.valueOptions.map((option) => (
//         <MenuItem key={option.value} value={option.value}>
//           {option.label}
//         </MenuItem>
//       ))}
//     </Select>
//   );
// }

function ManageGroup() {
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [addGroup, setAddGroup] = useState(true);
  const [groupId, setGroupId] = useState("");
  const [groupDesc, setGroupDesc] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [loaderDelete, setLoaderDelete] = useState(false);

  const [formData, setFormData] = useState({
    groupName: "",
    description: "",
  });

  const [groupList, setGroupList] = useState([]);
  const biUserData = useSelector(selectBiLogin);
  const userData = useSelector(currentUserData);
  const location = useLocation();
  const dispatch = useDispatch();
  const pathnames = location.pathname.split("/").filter((x) => x);
  //   const regionOptions = [
  //     { value: "us-east-1", label: "US East 1" },
  //     { value: "us-west-1", label: "US West 1" },
  //     { value: "eu-central-1", label: "EU Central 1" },
  //     // Add more region options as needed
  //   ];
  const menuList = [
    {
      group: "username-unique",
      description: "email@gmail.com",
    },
    {
      group: "username-uq",
      description: "email2@gmail.com",
    },
    {
      group: "test user",
      description: "test@gmail.com",
    },
  ];

  const columns = [
    // {
    //   field: "GroupName",
    //   headerName: "Group Name",
    //   headerClassName: "super-app-theme--header",
    //   // width: 150,
    //   flex: 1,
    //   renderHeader: () => <strong>{"Group Name"}</strong>,
    //   // editable: true,
    // },
    {
      field: "GroupName",
      headerName: "Group Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Group Name"}</strong>,
      renderCell: (params) => {
        const { id } = params;

        const handleAddUser = () => {
          setAddGroup(false);
          setGroupId(id);
          setGroupDesc(params.row.description);
        };

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              cursor: "pointer", // Add cursor pointer
            }}
            onClick={handleAddUser} // Attach onClick event handler
          >
            {params.value} {/* Display the content of the cell */}
          </div>
        );
      },
    },
    {
      field: "Description",
      headerName: "Description",
      headerClassName: "super-app-theme--header",
      // width: 150,
      flex: 1,
      renderHeader: () => <strong>{"Description"}</strong>,
      // editable: true,
    },

    {
      field: "actions",
      headerName: "Actions",
      // width: 100,
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong>{"Actions"}</strong>,

      // align: "left",
      flex: 1,
      renderCell: (params) => {
        const { id } = params;
        const handleDelete = () => {
          // Perform delete action based on the row data
          setDeleteId(id);
          handleClickDeleteOpen();
        };

        const handleAddUser = () => {
          setAddGroup(false);
          setGroupId(id);
          setGroupDesc(params.row.description);
        };

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center", // Align icons vertically in the center
              gap: "10px", // Add spacing between the icons
            }}
          >
            {/* <PersonAddAltOutlinedIcon
              color="action"
              style={{ cursor: "pointer", fontSize: 28 }} // Increase the icon size
              onClick={handleAddUser}
            /> */}
            {/* <DeleteOutlineOutlinedIcon
              color="action"
              style={{ cursor: "pointer", fontSize: 28 }} // Increase the icon size
              onClick={handleDelete}
            />
            <EditIcon
              color="action"
              style={{ cursor: "pointer", fontSize: 28 }} // Increase the icon size
              //   onClick={() => setAddGroup(false)}
            /> */}

            <img
              src={AddUserIcon}
              alt="add user"
              className="bg-color pointerCursor"
              onClick={handleAddUser}
            />
            <img
              src={DeleteIcon}
              alt="Delete"
              className="bg-color pointerCursor"
              onClick={handleDelete}
            />
            {/* <img
              src={EditTableIcon}
              alt="Edit"
              className="bg-color"
              onClick={() => setAddGroup(false)}
            /> */}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getTableDetails();
  }, []);

  const refreshAccessToken = async () => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId
    const tokenUserName =
      pathnames[0] === routeName ? biUserData?.UserName : userData?.UserName;

    const headers = {
      "Content-Type": "application/json",
      refreshToken: accessToken.RefreshToken,
      organizationId: organizationId,
      username: tokenUserName !== "" ? tokenUserName.toLowerCase() : "",
      // Add more headers if needed
      // "AnotherHeader": "Value"
    };

    // Make an API call to refresh the token using the refresh token
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/auth/tokenRefresh`,
        {},
        {
          headers,
        }
      )
      .then((res) => {
        console.log("res", res?.data?.data);
        if (pathnames[0] === routeName) {
        } else {
          console.log(userData);
          const reqObject = {
            UserId: userData.UserId,
            AWSUserId: userData.AWSUserId,
            UserName: userData.UserName,
            StatusId: userData.StatusId,
            Status: userData.Status,
            RoleId: userData.RoleId,
            Role: userData.Role,
            Organization: userData.Organization,
            UserEmail: userData.UserEmail,
            AuthKeys: {
              AccessToken: res?.data?.data?.AuthenticationResult.AccessToken,
              RefreshToken: accessToken.RefreshToken,
            },
          };
          dispatch(loginUserData(reqObject));
          // getTableDetails();
        }
      })
      .catch((error) => {
        console.error("Token refresh failed:", error);
        return error;
      });
  };

  const getTableDetails = () => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
      // "AnotherHeader": "Value"
    };

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/quickSight/groupList`, {
        headers: headers,
      })
      .then((response) => {
        // Handle the response data here

        setGroupList(response.data.data.GroupList);
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error("Error:", error);
        var flag = true;
        if (
          error.response &&
          error.response.data.message === "Token expired" &&
          flag === true
        ) {
          // If the token has expired, refresh the token and retry the API call
          refreshAccessToken().then((res) => {
            console.log("loop", res);
            flag = false;
          });
        }
      });
  };
  const handleClickDeleteOpen = () => {
    setOpenDelete(true);
  };
  const handleClickDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filterMenuList = () => {
    return groupList.filter(
      (item) =>
        item?.GroupName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.Description?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
      // "AnotherHeader": "Value"
    };
    const reqBody = {
      groupName: formData.groupName,
      description: formData.description,
    };
    if (formData.groupName === "") {
      toast("Please fill Group name", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else if (formData.description === "") {
      toast("Please fill description", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else {
      setButtonLoader(true);
      postApi(
        "/quickSight/createGroup",
        reqBody,
        accessToken.AccessToken,
        organizationId,
        headers
      )
        .then((response) => {
          //       // Handle the response data here
          //       // setGroupList(response.data.data.GroupList);
          console.log(response);
          getTableDetails();
          setFormData({
            groupName: "",
            description: "",
          });
          handleClose();
          setButtonLoader(false);
          if (response?.data.Status === 201) {
            toast("New Group added successfully", {
              type: "success",
              theme: "dark",
              autoClose: 2000,
            });
          } else if (response?.status === 500)
            toast(response.data.message, {
              type: "error",
              theme: "dark",
              autoClose: 2000,
            });
          else {
            toast("Some error occured", {
              type: "error",
              theme: "dark",
              autoClose: 2000,
            });
          }
        })

        .catch((error) => {
          // Handle any errors that occurred during the API call
          setButtonLoader(false);
          console.error("Error:", error.response.data.message);
        });
    }
    // axios
    //     .post(
    //       `${process.env.REACT_APP_BASE_URL}/quickSight/createGroup`,
    //       reqBody,
    //       {
    //         headers: headers,
    //       }
    //     )
    //     .then((response) => {
    //       // Handle the response data here
    //       // setGroupList(response.data.data.GroupList);
    //       console.log(response.data.message);
    //       getTableDetails();
    //       setFormData({
    //         groupName: "",
    //         description: "",
    //       });
    //       handleClose();

    //       toast("New Group added successfully", {
    //         type: "success",
    //         theme: "dark",
    //         autoClose: 2000,
    //       });
    //     })
    //     .catch((error) => {
    //       // Handle any errors that occurred during the API call
    //       console.error("Error:", error.response.data.message);
    //     })
  };

  const handleDelete = (name, event) => {
    setLoaderDelete(true);
    event.preventDefault();
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
      // "AnotherHeader": "Value"
    };
    const reqBody = {
      groupName: name,
    };

    deleteApi(
      "/quickSight/deleteGroup",
      reqBody,
      accessToken.AccessToken,
      organizationId,
      headers
    )
      .then((response) => {
        getTableDetails();

        handleClickDeleteClose();
        console.log(response.data);
        setLoaderDelete(false);

        response.data.Status === 200
          ? toast(" Group Deleted successfully", {
              type: "success",
              theme: "dark",
              autoClose: 2000,
            })
          : toast("Some error occured, Please try again", {
              type: "error",
              theme: "dark",
              autoClose: 2000,
            });
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error("Error:", error);
        setLoaderDelete(false);
      });
    // axios
    //   .delete(`${process.env.REACT_APP_BASE_URL}/quickSight/deleteGroup`, {
    //     data: reqBody,
    //     headers: headers,
    //   })
    //   .then((response) => {
    //     // Handle the response data here
    //     // setGroupList(response.data.data.GroupList);
    //     console.log(response.data.message);
    //     getTableDetails();

    //     handleClickDeleteClose();

    //     toast(" Group Deleted successfully", {
    //       type: "success",
    //       theme: "dark",
    //       autoClose: 2000,
    //     });
    //   })
    //   .catch((error) => {
    //     // Handle any errors that occurred during the API call
    //     console.error("Error:", error.response.data.message);
    //   });
  };
  const updateParentState = () => {
    setAddGroup(true);
  };

  // const handleReset = () => {};
  return (
    <div>
      {addGroup ? (
        <Paper elevation={2} sx={{ borderRadius: "5px" }}>
          <Box
            //   sx={{ height: 400, width: "100%" }}
            sx={{
              p: 4,
              alignItems: "center",
              "& .super-app-theme--header": {
                backgroundColor: primaryColor1,
                color: whiteColor,
              },
            }}
          >
            <Typography variant="h6" className="sub-header">
              Manage Group
            </Typography>

            <div
              className="top-header"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={handleOpen}
                sx={{ backgroundColor: whiteColor, color: primaryColor1 }}
              >
                Add Group
              </Button>

              <div>
                <TextField
                  id="outlined-basic"
                  label="Search Group"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: <SearchIcon style={{ opacity: 0.5 }} />,
                  }}
                  //   sx={{ paddingRight: "20px" }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>

              <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <DialogTitle>Add New Group</DialogTitle>
                  <DialogTitle>
                    <CloseIcon
                      onClick={handleClose}
                      className="pointerCursor"
                    />
                  </DialogTitle>
                </div>
                <DialogContent>
                  <form onSubmit={handleSubmit}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="groupName"
                      label="Group Name"
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="groupName"
                      value={formData.groupName}
                      onChange={handleInputChange}
                    />

                    <TextField
                      multiline
                      rows={4}
                      margin="dense"
                      id="description"
                      label="Description"
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                    />
                  </form>
                </DialogContent>
                <DialogActions
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    p: 3,
                  }}
                >
                  {buttonLoader ? (
                    <div className="loader"></div>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      sx={{ backgroundColor: primaryColor1 }}
                    >
                      Save
                    </Button>
                  )}

                  {/* <Button
                    variant="contained"
                    onClick={handleReset}
                    sx={{ backgroundColor: primaryColor1 }}
                  >
                    RESET
                  </Button> */}
                </DialogActions>
              </Dialog>
            </div>
            <DataGrid
              rows={filterMenuList()}
              columns={columns}
              getRowId={(row) => row.GroupName}
              initialState={{
                ...menuList.initialState,
                pagination: { paginationModel: { pageSize: 5 } },
              }}
              pageSizeOptions={[5, 10, 25]}
              disableRowSelectionOnClick
            />
          </Box>
          <Dialog
            open={openDelete}
            onClose={handleClickDeleteClose}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <DialogTitle>
                {"Delete Group"}
                {/* <span
                // className="dialogHighlight"
                >
                  {deleteId}
                </span> */}
              </DialogTitle>
              <DialogTitle>
                <CloseIcon
                  onClick={handleClickDeleteClose}
                  className="pointerCursor"
                />
              </DialogTitle>
            </div>

            <DialogContent>
              <DialogContentText>
                {`Are you sure you want to delete`} <span>{deleteId}</span>{" "}
                {"?"}
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 3,
              }}
            >
              {loaderDelete ? (
                <div className="loader"></div>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    sx={{
                      backgroundColor: whiteColor,
                      color: primaryColor1,
                      borderColor: primaryColor1,
                    }}
                    onClick={(event) => {
                      handleDelete(deleteId, event);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: primaryColor1 }}
                    onClick={handleClickDeleteClose}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </DialogActions>
          </Dialog>
        </Paper>
      ) : (
        <Paper elevation={2} sx={{ borderRadius: "5px", height: "82vh" }}>
          <Box
            //   sx={{ height: 400, width: "100%" }}
            sx={{
              p: 4,
              alignItems: "center",
              "& .super-app-theme--header": {
                backgroundColor: primaryColor1,
                color: whiteColor,
              },
            }}
          >
            <AddGroupUser
              groupId={groupId}
              desc={groupDesc}
              updateParentState={updateParentState}
            />
          </Box>
        </Paper>
      )}
      <ToastContainer />
    </div>
  );
}

export default ManageGroup;
