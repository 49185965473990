export const jsonTransform = (userArr) => {
  const transformedArray = userArr.map((user) => {
    return {
      label: user.CognitoUserName, // Set the label property to the user's UserName
      value: user.CognitoUserName, // Set the value property to the user's Email (or any other property you desire)
    };
  });

  return transformedArray;
};
