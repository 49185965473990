import React, { useEffect, useState } from "react";
import {
  Typography,
  Popover,
  IconButton,
  Toolbar,
  AppBar,
  Grid,
  useMediaQuery,
  Divider,
  List,
  ListItem,
  ListItemText,
  Box,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../navbar/Navbar";
import NavbarInner from "../navbarInner/NavbarInner";
import "./analytics.scss";
import "./loader.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import {
  selectBiLogin,
  setBiLogin,
  setBiUserToken,
  currentUserData,
} from "../user/LoginSlice";
import axios from "axios";
import { routeName } from "../commonStrings/CommonStrings";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import AnalyticsNavbar from "./AnalyticsNavbar";

function Analytics1() {
  const [recValue, setRecValue] = useState(false);
  const [loaderFlag, setLoaderFlag] = useState(false);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const dispatch = useDispatch();
  const biUser = useSelector(selectBiLogin);
  const biUserData = useSelector(selectBiLogin);
  const userData = useSelector(currentUserData);

  /**
   * ! New API response variables
   */

  const [menuList, setMenuList] = useState([]);
  const [menuFlag, setMenuFlag] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const Loader = () => {
    return (
      <div className="spinner-outq">
        <div className="spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    // pathnames[0] === "bi";
    const messageHandler = (event) => {
      console.log("Received message:", event.data);

      if (event.data !== "request_data") {
        // event.data?.data !== undefined
        //   ? setLoaderFlag(true)
        //   : setLoaderFlag(false);
        setLoaderFlag(true);
        setIsInitialized(false);
        setRecValue(true);

        /**
         *
         * !API call for verification
         */
        // if (event.data?.data ) {
        //   console.log("2");
        //   setLoaderFlag(true);
        // } else {
        //   console.log("3");
        //   var biReduxData = event.data?.data;
        //   dispatch(setBiLogin(biReduxData));
        //   setLoaderFlag(false);
        // }

        if (event.data?.data.hasOwnProperty("UserId")) {
          var biReduxData = event.data?.data;
          console.log(biReduxData);
          dispatch(setBiLogin(biReduxData));
          setIsInitialized(true);

          setLoaderFlag(false);
        } else {
          dispatch(setBiLogin({}));
          setLoaderFlag(true);
          setIsInitialized(false);
        }
      } else {
        pathnames[0] === "bi" ? setLoaderFlag(true) : setLoaderFlag(false);
      }

      //   if (event.data) {
      //     console.log("Data received from the parent: ", event.data);
      //   }
    };

    if (pathnames[0] === routeName) {
      if (recValue === false) {
        window.addEventListener("message", messageHandler);
        window.parent.postMessage("request_data", "*");
      }

      return () => {
        window.removeEventListener("message", messageHandler);
      };
    } else {
    }
  }, [recValue]);

  const getMenuList = () => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token

    const organizationId =
      pathnames[0] === routeName
        ? //  "95145b12-447b-4716-b6c8-040361d266f0"
          biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
      // "AnotherHeader": "Value"
    };

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/menu/list`, { headers: headers })
      .then((response) => {
        // Handle the response data here
        // console.log(response.data.data.bi);
        setMenuList(response.data.data.bi);
        setMenuFlag(false);
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    setMenuFlag(true);
    if (pathnames[0] === routeName) {
      if (isInitialized) {
        getMenuList();
        //getDashboardDetails(dashboardId);
      }
    } else {
      getMenuList();
      //getDashboardDetails(dashboardId);
    }
  }, [isInitialized]);

  const theme = createTheme(); // Create a default theme
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  //   const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div>
      {loaderFlag ? (
        <Loader />
      ) : (
        <ThemeProvider theme={theme}>
          <div>
            {pathnames[0] === "bi" ? "" : <Navbar />}
            {pathnames[0] === "bi" ? (
              ""
            ) : (
              <NavbarInner currentLink="analytics" />
            )}

            <Divider />
            {pathnames[0] === "bi" && pathnames[1] === "analytics" ? (
              menuFlag ? (
                <Loader />
              ) : (
                <AnalyticsNavbar
                  items={menuList}
                  isInitialized={isInitialized}
                />
              )
            ) : menuFlag ? (
              <Loader />
            ) : (
              <AnalyticsNavbar items={menuList} isInitialized={isInitialized} />
            )}

            {/* {pathnames[0] === "bi" && pathnames[1] === "admin" ? (
              <Navigate to="/bi/admin/manage-user" />
            ) : (
              ""
            )} */}
            {/* {menuFlag ? (
              <Loader />
            ) : (
              <AnalyticsNavbar items={menuList} isInitialized={isInitialized} />
            )} */}
          </div>
        </ThemeProvider>
      )}
    </div>
  );
}

export default Analytics1;
