export const jsonTransformLevel1 = (levels) => {
  const transformedArray = levels.map((user) => {
    return {
      label: user.member_hrchy_l1,
      value: user.member_hrchy_l1,
    };
  });

  return transformedArray;
};

export const jsonTransformLevel2 = (levels) => {
  const transformedArray = levels.map((user) => {
    return {
      label: `${user.member_hrchy_l2} > ${user.member_hrchy_l1}`,
      value: `${user.member_hrchy_l2} > ${user.member_hrchy_l1}`,
    };
  });

  return transformedArray;
};

export const jsonTransformLevel3 = (levels) => {
  const transformedArray = levels.map((user) => {
    return {
      label: `${user.member_hrchy_l3} > ${user.member_hrchy_l2}`,
      value: `${user.member_hrchy_l3} > ${user.member_hrchy_l2}`,
    };
  });

  return transformedArray;
};

export const jsonTransformLevel4 = (levels) => {
  const transformedArray = levels.map((user) => {
    return {
      label: `${user.member_hrchy_l4} > ${user.member_hrchy_l3} > ${user.member_hrchy_l2}`,
      value: `${user.member_hrchy_l4} > ${user.member_hrchy_l3} > ${user.member_hrchy_l2}`,
    };
  });

  return transformedArray;
};

export const jsonTransformLevel5 = (levels) => {
  const transformedArray = levels.map((user) => {
    return {
      label: `${user.member_hrchy_l5} > ${user.member_hrchy_l4} > ${user.member_hrchy_l3} > ${user.member_hrchy_l2} > ${user.member_hrchy_l1}`,
      value: `${user.member_hrchy_l5} > ${user.member_hrchy_l4} > ${user.member_hrchy_l3} > ${user.member_hrchy_l2} > ${user.member_hrchy_l1}`,
    };
  });

  return transformedArray;
};
