import { createSlice } from "@reduxjs/toolkit";

const initialState = { routePath: "" };
// const initialState = [];
export const AdminSlice = createSlice({
  name: "routePath",
  initialState,

  reducers: {
    savePath: (state, action) => {
      state.routePath = action.payload;
    },
  },
});
export const { savePath } = AdminSlice.actions;

export const currentPath = (state) => state.routePath.routePath;
// export const selectUser = (state) => state.login;

export default AdminSlice.reducer;
