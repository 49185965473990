// Function to deep clone an object
const clone = obj => JSON.parse(JSON.stringify(obj));

// Create a new node
export const createNode = (MenuId, MenuName, isMenuParent, DashboardId, SheetId) => {
    return { MenuId, MenuName, isMenuParent, DashboardId, SheetId, Children: [] };
};

// Add a new node to the tree
export const addNode = (tree, parentId, newNode) => {
    if (parentId === null) {
        return [...tree, newNode];
    }

    const newTree = clone(tree);

    const findAndAdd = nodes => {
        for (let node of nodes) {
            if (node.MenuId === parentId) {
                node.Children.push(newNode);
                return true;
            }

            if (node.Children) {
                if (findAndAdd(node.Children)) return true;
            }
        }

        return false;
    };

    findAndAdd(newTree);
    return newTree;
};

// Remove a node from the tree
export const removeNode = (tree, nodeId) => {
    const newTree = clone(tree);

    const findAndRemove = nodes => {
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].MenuId === nodeId) {
                nodes.splice(i, 1);
                return true;
            }

            if (nodes[i].Children) {
                if (findAndRemove(nodes[i].Children)) return true;
            }
        }

        return false;
    };

    findAndRemove(newTree);
    return newTree;
};

// Update a node in the tree
export const updateNode = (tree, nodeId, newTitle, newHasChild, newDashboardId, newSheetId) => {
    const newTree = clone(tree);

    const findAndUpdate = nodes => {
        for (let node of nodes) {
            if (node.MenuId === nodeId) {
                node.MenuName = newTitle;
                node.isMenuParent = newHasChild;
                node.DashboardId = newDashboardId;
                node.SheetId = newSheetId;
                return true;
            }

            if (node.Children) {
                if (findAndUpdate(node.Children)) return true;
            }
        }

        return false;
    };

    findAndUpdate(newTree);
    return newTree;
};