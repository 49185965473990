import styles from "./themes.scss";

export const primaryColor1 = styles.primaryColor1;
export const buttonColorCommon = styles.buttonColorCommon;
export const listSelectionColor = styles.listSelectionColor;
export const whiteColor = styles.whiteColor;
export const redColor = styles.redColor;
export const itemHover = styles.itemHover;
export const blackColor = styles.blackColor;
export const backgroundBlue = styles.backgroundBlue;
export const hoverBlueDark = styles.hoverBlueDark;
export const itemHover1 = styles.itemHover1;
