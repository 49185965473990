import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AppBar,
  Toolbar,
  Grid,
  Box,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Button,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavbarLogo from "../../assets/CQWIk-1.png";
import "./navbarInner.scss";
import { whiteColor, primaryColor1 } from "../commonStyles/styles";
import { links, linksAdmin, linksAdminBi, linksBi } from "./NavbarData";
import { selectBiLogin, selectUser } from "../user/LoginSlice";
import { currentPath } from "../admin/AdminSlice";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { routeName } from "../commonStrings/CommonStrings";

// const pages = ["Home", "Dashboard"];

function NavbarInner(props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const location = useLocation();
  // const [hoveredLinkId, setHoveredLinkId] = React.useState(null);
  const [navArr, setNavArr] = useState([]);
  const [regionValue, setRegionValue] = useState("");
  const user = useSelector(selectUser);
  const biUser = useSelector(selectBiLogin);
  const routePath = useSelector(currentPath);

  const navigate = useNavigate();

  const regionOptions = [
    { value: "", label: "Select a Region" },
    { value: "us-east-1", label: "US East 1" },
    { value: "us-west-1", label: "US West 1" },
    { value: "eu-central-1", label: "EU Central 1" },
    // Add more region options as needed
  ];
  const pathnames = location.pathname.split("/").filter((x) => x);
  useEffect(() => {
    let tempArray = [];
    // user === "admin" ? (pathnames[0] === "bi" ? tempArray= linksAdminBi : tempArray = linksAdmin) : (tempArray = links);
    // pathnames[0] === "bi"
    //   ? user === "admin"
    //     ? (tempArray = linksAdminBi)
    //     : (tempArray = linksBi)
    //   : user === "admin"
    //   ? (tempArray = linksAdmin)
    //   : (tempArray = links);

    // if (pathnames[0] === "bi") {
    //   console.log("biUser", biUser);
    // } else {
    // }
    // if (pathnames[0] === "bi") {
    //   console.log("biUser", biUser);
    // } else {
    // }

    pathnames[0] === routeName
      ? biUser?.RoleId === 1
        ? (tempArray = linksAdminBi)
        : (tempArray = linksBi)
      : user === "admin"
      ? (tempArray = linksAdmin)
      : (tempArray = links);

    setNavArr(tempArray);
    if (routePath !== "") {
    }
  }, [user, location.pathname, routePath]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
    /* eslint-disable-next-line */
    if (page === "Home") {
      navigate("/home");
    } else if (page === "Dashboard") {
      navigate("/analytics");
    }
  };

  const handleRegionChange = (event) => {
    setRegionValue(event.target.value);
  };
  return (
    <div>
      <AppBar
        position="sticky"
        sx={{ backgroundColor: whiteColor, height: 60 }}
        elevation={0}
      >
        {/* <Container maxWidth="xl"> */}
        <div style={{ padding: "0% 1% 0% 1%" }}>
          <Toolbar disableGutters>
            {/* <Typography
              variant="h6"
              noWrap
              component="a"
              href={pathnames[0] === "bi" ? "/bi/analytics" : "/home"}
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                // color: "inherit",
                textDecoration: "none",
              }}
            >
              <img className="logo-inner" src={NavbarLogo} alt="Logo" />
            </Typography> */}

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                // color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {navArr.map((page) => (
                  <MenuItem
                    key={page.id}
                    onClick={() => handleCloseNavMenu(page.text)}
                    sx={{ color: "black" }}
                  >
                    <Link to={page.url} className="linkStyle">
                      {page.text}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/* <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img className="logo-inner" src={NavbarLogo} alt="Logo" />
            </Typography> */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
              }}
            >
              {navArr.map((page, index) => (
                <Button
                  key={page.id}
                  onClick={() => handleCloseNavMenu(page.text)}
                  sx={{
                    my: 2,
                    color: "black",
                    display: "block",
                    marginRight: "10px", // Adjust spacing between buttons if needed
                    whiteSpace: "nowrap", // Prevent text from wrapping
                    textOverflow: "ellipsis", // Show ellipsis if text exceeds available space
                    overflow: "hidden", // Hide any overflowing text
                  }}
                  // className={`linkStyle${
                  //   location.pathname === page.url ||
                  //   (index === 0 && !location.pathname) ||
                  //   hoveredLinkId === page.id
                  //     ? " active"
                  //     : ""
                  // }`}
                >
                  <Link
                    to={page.url}
                    className={`linkStyle${
                      location.pathname === page.url ||
                      (index === 0 && !location.pathname)
                        ? // ||
                          // hoveredLinkId === page.id
                          " active"
                        : ""
                    }`}
                  >
                    {page.text}
                  </Link>
                </Button>
              ))}
            </Box>
            {/* 
            <Grid
              container
              spacing={4}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item>
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  margin="dense"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: primaryColor1, // Replace "your_custom_color" with your desired color
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: primaryColor1, // Replace "your_custom_color" with your desired color
                      },
                    },
                    minWidth: "150px",
                  }}
                >
                  <InputLabel id="region-label" style={{ fontSize: "80%" }}>
                    Region
                  </InputLabel>
                  <Select
                    labelId="region-label"
                    id="aws-region"
                    // value={formData.awsRegion}
                    value={regionValue}
                    label="Region"
                    name="awsRegion"
                    onChange={handleRegionChange}
                  >
                    {regionOptions.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                {props.currentLink === "analytics" ? (
                  <CachedOutlinedIcon className="icon-color" />
                ) : (
                  ""
                )}
              </Grid>
              <Grid item>
                {props.currentLink === "analytics" ? (
                  <BookmarkBorderOutlinedIcon className="icon-color" />
                ) : (
                  ""
                )}
              </Grid>
            </Grid> */}
          </Toolbar>
        </div>
        {/* </Container> */}
      </AppBar>
    </div>
  );
}

export default NavbarInner;
