// api.js

import axios from "axios";

const callApi = async (
  url,
  method = "GET",
  data = null,
  accessToken,
  organizationId,
  headers = {}
) => {
  const apiBaseUrl = process.env.REACT_APP_BASE_URL; // Set your base API URL here

  const defaultHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken.AccessToken}`,
    organizationId: organizationId,
    // Add more headers if needed
    // "AnotherHeader": "Value"
  };

  try {
    const response = await axios({
      url: `${apiBaseUrl}${url}`,
      method: method,
      data: data,
      headers: { ...defaultHeaders, ...headers }, // Merge default headers with the passed headers
    });

    // Return the response data

    return response.data;
  } catch (error) {
    // Handle API call errors
    console.error("API Error:", error.response);
    return error.response;
    // throw error; // Re-throw the error to handle it in the component
  }
};

export const getApi = async (
  url,
  data,
  accessToken,
  organizationId,
  headers
) => {
  return callApi(url, "GET", data, accessToken, organizationId, headers);
};

export const postApi = async (
  url,
  data,
  accessToken,
  organizationId,
  headers = {}
) => {
  return callApi(url, "POST", data, accessToken, organizationId, headers);
};

export const deleteApi = async (
  url,
  data,
  accessToken,
  organizationId,
  headers = {}
) => {
  // The function simply returns the result of calling another function called callApi
  // The parameters of the callApi function are passed through from deleteApi
  return callApi(url, "DELETE", data, accessToken, organizationId, headers);
};
