import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./components/user/Login";
import UnknownRoutes from "./components/unknownRoutes/UnknownRoutes";
import Home from "./components/home/Home";
import Analytics1 from "./components/analytics/Analytics1";
// import Settings from "./components/Settings/Settings";
// import SignUp from "./components/user/SignUp";
// import SignOut from "./components/user/SignOut";
// import ConfirmAccount from "./components/user/ConfirmAccount";
import "./App.scss";
import S3Manager from "./components/s3/S3Manager";
import Admin from "./components/admin/Admin";
// import Bi from "./components/Bi/Bi";
// import CloudqBi from "./components/cloudqBI/CloudqBi";
// import UserManagement from "./components/userManagement/UserManagement";
// import BiFrame from "./components/cloudqBI/BiFrame";
import { useSelector } from "react-redux";
import { selectUser, selectBiLogin } from "./components/user/LoginSlice";

function App() {
  const userToken = useSelector(selectUser);
  // const location = useLocation();
  // const pathnames = location.pathname.split("/").filter((x) => x);

  /**
   * !API call to very user and protect routes
   */
  const isLoggedIn = () => {
    if (userToken === "admin" || userToken === "user") {
      return true;
    } else {
      return false;
    }
  };

  // Define the protected route component (route guard)
  const ProtectedRouteWrapper = ({ element }) => {
    if (isLoggedIn()) {
      return element;
    } else {
      // Redirect to login page if the user is not logged in
      return <Navigate to="/login" />;
    }
  };

  return (
    <div className="">
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/bi" element={<Bi />} /> */}
          <Route path="*" element={<UnknownRoutes />} />
          <Route
            path="/home"
            element={<ProtectedRouteWrapper element={<Home />} />}
          />
          <Route
            path="/analytics"
            element={<ProtectedRouteWrapper element={<Analytics1 />} />}
          />
          <Route path="/s3-file-manager" element={<S3Manager />} />
          <Route path="/admin/*" element={<Admin />} />
          {/* <Route path="/settings" element={<Settings />} />
               
                    <Route path="/client/frame/0" element={<BiFrame />} /> */}
          {/* <Route path="/user-management/*" element={<UserManagement />} /> */}
          ( /** * ! Exclusively for BI */)
          <Route path="/bi" element={<Navigate to="/bi/analytics" />} />
          {/* <Route path="/bi/login" element={<Bi />} /> */}
          <Route path="/bi/admin/*" element={<Admin />} />
          <Route path="/bi/analytics" element={<Analytics1 />} />
          <Route path="/bi/s3-file-manager" element={<S3Manager />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
