import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectBiLogin, currentUserData } from "../user/LoginSlice";
import { primaryColor1, whiteColor, redColor } from "../commonStyles/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import { routeName } from "../commonStrings/CommonStrings";
import { postApi } from "..//api/Api";
import { styled } from "@mui/material/styles";

function EditManageUser({ editOpen, editClose, editList, tableRefresh }) {
  const [formData, setFormData] = useState({
    userName: editList.UserName,
    name: "",
    // emailId: "",
    password: "",
    userStatus: "",
    s3Path: "",
    emailId: editList.Email,
    role: "",
    s3Status: false,
  });
  const [toggleStatus, setToggleStatus] = useState(false);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const biUserData = useSelector(selectBiLogin);
  const userData = useSelector(currentUserData);

  const roleList = [
    // { label: "Reader", value: "READER" },
    { label: "Admin", value: "ADMIN" },
    { label: "Author", value: "AUTHOR" },
  ];
  useEffect(() => {}, []);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleToggleS3 = (e) => {
    setToggleStatus(!toggleStatus);
  };

  const handleSubmit = (event) => {
    // console.log(userNameEdit);
    // console.log(editList.UserName);
    var tempRole = "";
    console.log(formData.role);
    if (formData.role === "ADMIN") {
      tempRole = 1;
    } else if (formData.role === "AUTHOR") {
      tempRole = 2;
    } else if (formData.role === "READER") {
      tempRole = 3;
    }
    console.log(editList);

    event.preventDefault();

    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys;
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
    };
    const reqBody = {
      userName: editList.CognitoUserName,
      role: tempRole,
      email: editList.Email,
    };

    postApi(
      "/quickSight/updateUserRole",
      reqBody,
      accessToken.AccessToken,
      organizationId,
      headers
    )
      .then((res) => {
        console.log(res);
        if (res?.status === true) {
          toast("User Edited Successfully", {
            type: "success",
            theme: "dark",
            autoClose: 2000,
          });
          tableRefresh();
          editClose();
        } else {
          toast(res?.message, {
            type: "error",
            theme: "dark",
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast("Some error occured", {
          type: "error",
          theme: "dark",
          autoClose: 2000,
        });
      });
  };

  return (
    <Dialog
      open={editOpen}
      onClose={editClose}
      maxWidth="md"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle>Edit User Details</DialogTitle>
        <DialogTitle>
          <CloseIcon onClick={editClose} className="pointerCursor" />
        </DialogTitle>
      </div>
      <DialogContent
        sx={{
          minHeight: "200px", // Set a minimum height
          minWidth: "500px", // Set a minimum width
          p: 3,
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                size="small"
                margin="dense"
                id="username"
                label={
                  <span>
                    Username
                    <Typography component="span" sx={{ color: redColor }}>
                      *
                    </Typography>
                  </span>
                }
                fullWidth
                variant="outlined"
                type="text"
                name="userName"
                value={formData.userName}
                onChange={handleInputChange}
                // required
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                margin="dense"
                id="name"
                label={
                  <span>
                    Name
                    <Typography component="span" sx={{ color: redColor }}>
                      *
                    </Typography>
                  </span>
                }
                fullWidth
                variant="outlined"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                margin="dense"
                id="password"
                label={
                  <span>
                    Password
                    <Typography component="span" sx={{ color: redColor }}>
                      *
                    </Typography>
                  </span>
                }
                fullWidth
                variant="outlined"
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <TextField
                  disabled={toggleStatus ? false : true}
                  size="small"
                  margin="dense"
                  id="s3Path"
                  label="S3 Path"
                  fullWidth
                  variant="outlined"
                  type="text"
                  name="s3Path"
                  value={formData.s3Path}
                  onChange={handleInputChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <div>
                  <label>S3 Status</label>
                </div>
                <div className="toggle-switch">
                  <input
                    className="toggle-input"
                    id="toggle"
                    type="checkbox"
                    onClick={handleToggleS3}
                  />
                  <label className="toggle-label" htmlFor="toggle" />
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              >
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role"
                  id="role"
                  value={formData.role}
                  label="Role"
                  name="role"
                  onChange={handleInputChange}
                >
                  {roleList.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {/* <Button type="submit" variant="contained" sx={{ mt: 2, mr: 1 }}>
                  Save
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  onClick={handleReset}
                  sx={{ mt: 2 }}
                >
                  Reset
                </Button> */}
        </form>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 3,
        }}
      >
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{ backgroundColor: primaryColor1 }}
        >
          Save
        </Button>
      </DialogActions>
      <ToastContainer />
    </Dialog>
  );
}
export default EditManageUser;
