export const linksAdmin = [
  {
    id: 1,
    url: "/analytics",
    text: "Analytics",
    icon: "",
  },
  // {
  //   id: 2,
  //   url: "/s3-file-manager",
  //   text: "S3 File Manager",
  //   icon: "",
  // },
  {
    id: 3,
    url: "/admin/manage-user",
    text: "Admin",
    icon: "",
  },
];

export const links = [
  {
    id: 1,
    url: "/analytics",
    text: "Analytics",
    icon: "",
  },
  // {
  //   id: 2,
  //   url: "/s3-file-manager",
  //   text: "S3 File Manager",
  //   icon: "",
  // },
];

export const linksAdminBi = [
  {
    id: 1,
    url: "/bi/analytics",
    text: "Analytics",
    icon: "",
  },
  // {
  //   id: 2,
  //   url: "/bi/s3-file-manager",
  //   text: "S3 File Manager",
  //   icon: "",
  // },
  {
    id: 3,
    url: "/bi/admin/manage-user",
    text: "Admin",
    icon: "",
  },
];

export const linksBi = [
  {
    id: 1,
    url: "/bi/analytics",
    text: "Analytics",
    icon: "",
  },
  // {
  //   id: 2,
  //   url: "/bi/s3-file-manager",
  //   text: "S3 File Manager",
  //   icon: "",
  // },
];
