import React, { useState } from "react";
import { Box, Paper, Typography, Button, TextField } from "@mui/material";
import Navbar from "../navbar/Navbar";
import NavbarInner from "../navbarInner/NavbarInner";
import { useLocation } from "react-router-dom";
import { primaryColor1, whiteColor, redColor } from "../commonStyles/styles";
import { DataGrid } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import "./s3manager.scss";
import DownloadCloudIcon from "../../assets/download-cloud.png";
import UploadCloudIcon from "../../assets/upload-cloud.png";
import FolderIcon from "../../assets/Folder.png";
import FileIcon from "../../assets/File.png";

function S3Manager() {
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const menuList = [
    {
      UserName: "abc",
      Type: "folder",
      Size: "3GB",
      Date: "July 7",
    },
    {
      UserName: "bz",
      Type: "folder",
      Size: "3GB",
      Date: "July 7",
    },
    {
      UserName: "test1",
      Type: "file",
      Size: "3GB",
      Date: "July 7",
    },
    {
      UserName: "test2",
      Type: "folder",
      Size: "3GB",
      Date: "July 7",
    },
    {
      UserName: "test3",
      Type: "file",
      Size: "3GB",
      Date: "July 7",
    },
  ];
  const columns = [
    {
      field: "UserName",
      headerName: "username",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Name"}</strong>,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" gap="10px">
          {params.row.Type === "folder" ? (
            <img src={FolderIcon} alt="Menu Config" className="s3-bg-color" />
          ) : (
            <img src={FileIcon} alt="Menu Config" className="s3-bg-color" />
          )}
          <span>{params.row.UserName}</span>
        </Box>
      ),
    },
    {
      field: "Type",
      headerName: "Sub Menu",
      headerClassName: "super-app-theme--header",
      // width: 150,
      flex: 1,
      renderHeader: () => <strong>{"Type"}</strong>,
      // editable: true,
    },
    {
      field: "Size",
      headerName: "Sub Menu",
      headerClassName: "super-app-theme--header",
      // width: 150,
      flex: 1,
      renderHeader: () => <strong>{"Size"}</strong>,
      // editable: true,
    },
    {
      field: "Date",
      headerName: "Sub Menu",
      headerClassName: "super-app-theme--header",
      // width: 150,
      flex: 1,
      renderHeader: () => <strong>{"TypDate Modifiede"}</strong>,
      // editable: true,
    },
  ];

  const filterMenuList = () => {
    return menuList.filter(
      (item) =>
        item?.UserName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.Type?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.Size?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.Date?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  return (
    <div>
      {pathnames[0] === "bi" ? "" : <Navbar />}
      <NavbarInner />
      {/* <h1>S3 Manager</h1> */}
      <div style={{ padding: "1%" }}>
        <Paper elevation={1} sx={{ borderRadius: "5px", height: "100%" }}>
          <Box
            // sx={{ height: 400, width: "100%" }}
            sx={{
              p: 3,
              height: "100%",
              alignItems: "center",
              "& .super-app-theme--header": {
                backgroundColor: primaryColor1,
                color: whiteColor,
              },
            }}
          >
            <Typography variant="h5" className="s3-subheader">
              S3 File Manager
            </Typography>
            <div className="s3-header">
              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  size="medium"
                  variant="outlined"
                  startIcon={
                    <img
                      src={DownloadCloudIcon}
                      alt="Menu Config"
                      className="s3-bg-color"
                    />
                  }
                  // onClick={handleClickOpen}

                  sx={{ backgroundColor: primaryColor1, color: whiteColor }}
                >
                  Download
                </Button>
                <Button
                  size="medium"
                  variant="outlined"
                  startIcon={
                    <img
                      src={UploadCloudIcon}
                      alt="Menu Config"
                      className="s3-bg-color"
                    />
                  }
                  // onClick={handleClickOpen}
                  sx={{ backgroundColor: whiteColor, color: primaryColor1 }}
                >
                  Upload
                </Button>
              </div>

              <TextField
                id="outlined-basic"
                label="Search User"
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: <SearchIcon style={{ opacity: 0.5 }} />,
                }}
                //   sx={{ paddingRight: "20px" }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <DataGrid
              rows={filterMenuList()}
              columns={columns}
              getRowId={(row) => row.UserName}
              initialState={{
                ...menuList.initialState,
                pagination: { paginationModel: { pageSize: 5 } },
              }}
              pageSizeOptions={[5, 10, 25]}
              disableRowSelectionOnClick
              checkboxSelection
            />
          </Box>
        </Paper>
      </div>
    </div>
  );
}

export default S3Manager;
