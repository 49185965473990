// store.js

import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist"; // Import persistStore
import persistedReducer from "./rootReducer";

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store); // Create the persistor

export { store, persistor }; // Export both the store and the persistor
