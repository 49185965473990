import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectBiLogin, currentUserData } from "../../../user/LoginSlice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import { useLocation } from "react-router-dom";
import { routeName } from "../../../commonStrings/CommonStrings";
import { postApi } from "../../../api/Api";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { primaryColor1 } from "../../../commonStyles/styles";

const RequiredAsterisk = styled("span")({
  color: "red", // Customize the color or any other styles here
});

function AddNodeModal({ isOpen, onRequestClose, onAddNode, ParentMenuId }) {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const biUserData = useSelector(selectBiLogin);
  const userData = useSelector(currentUserData);
  const [MenuName, setNodeTitle] = useState("");
  const [isMenuParent, setHasChild] = useState(false);
  const [DashboardId, setDashboardId] = useState("");
  const [SheetId, setSheetId] = useState("");

  const handleSubmit = (event) => {
    // Disable button to prevent multiple clicks for same data
    // event.target.disabled = true;
    event.preventDefault();
    // if (MenuName.trim() === "") {
    //   console.log("Menu Name is required.");
    //   event.target.disabled = false;
    //   return;
    // }
    // Change button text to indicate data is saving
    // event.target.innerText = "Please wait ...";

    if (MenuName === "") {
      toast("Menu Name cannot be empty", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else if (DashboardId === "" && isMenuParent === false) {
      console.log("1");
      toast("Dashboard ID cannot be empty", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else if ((SheetId === "") & (isMenuParent === false)) {
      toast("Sheet ID cannot be empty", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else {
      event.target.disabled = true;
      const accessToken =
        pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token

      const organizationId =
        pathnames[0] === routeName
          ? //  "95145b12-447b-4716-b6c8-040361d266f0"
            biUserData?.Organization.OrganizationId
          : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

      const payload = {
        ParentMenuId,
        MenuName,
        isMenuParent,
        DashboardId: isMenuParent ? null : DashboardId,
        SheetId: isMenuParent ? null : SheetId,
      };

      postApi("/menu/create", payload, accessToken, organizationId)
        .then((response) => {
          if (isMenuParent) {
            onAddNode(MenuName, isMenuParent, null, null);
          } else {
            onAddNode(MenuName, isMenuParent, DashboardId, SheetId);
          }
          setNodeTitle("");
          setHasChild(false);
          setDashboardId("");
          setSheetId("");
        })
        .catch((error) => {
          console.error("Error creating menu:", error);
        });
    }

    if (MenuName.trim() === "") {
      console.log("Menu Name is required.");
      return;
    }
  };

  return (
    <Dialog open={isOpen} onClose={onRequestClose}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle>Add Child Node</DialogTitle>
        <DialogTitle>
          <CloseIcon onClick={onRequestClose} className="pointerCursor" />
        </DialogTitle>
      </div>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            id="nodeTitle"
            label={
              <span>
                Menu
                <RequiredAsterisk>*</RequiredAsterisk>
              </span>
            }
            fullWidth
            variant="outlined"
            type="text"
            value={MenuName}
            onChange={(e) => setNodeTitle(e.target.value)}
          />
          {!isMenuParent && (
            <>
              <TextField
                margin="dense"
                id="dashboardId"
                label={
                  <span>
                    Dashboard Id
                    <RequiredAsterisk>*</RequiredAsterisk>
                  </span>
                }
                fullWidth
                variant="outlined"
                type="text"
                value={DashboardId}
                onChange={(e) => setDashboardId(e.target.value)}
              />
              <TextField
                margin="dense"
                id="sheetId"
                label={
                  <span>
                    Sheet ID
                    <RequiredAsterisk>*</RequiredAsterisk>
                  </span>
                }
                fullWidth
                variant="outlined"
                type="text"
                value={SheetId}
                onChange={(e) => setSheetId(e.target.value)}
              />
            </>
          )}
          <FormControlLabel
            control={
              <Switch
                checked={isMenuParent}
                onChange={() => setHasChild(!isMenuParent)}
              />
            }
            label="Has a Child"
          />
        </form>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{ backgroundColor: primaryColor1 }}
        >
          Save
        </Button>
      </DialogActions>
      <ToastContainer />
    </Dialog>
  );
}

export default AddNodeModal;
