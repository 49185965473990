import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { primaryColor1 } from "../../../commonStyles/styles";

const RequiredAsterisk = styled("span")({
  color: "red", // Customize the color or any other styles here
});

const EditModal2 = ({ show, handleClose, initialData, handleSave }) => {
  const [MenuName, setTitle] = useState("");
  const [isMenuParent, setHasChild] = useState(false);
  const [DashboardId, setDashboardId] = useState("");
  const [SheetId, setSheetId] = useState("");

  useEffect(() => {
    if (initialData) {
      setTitle(initialData.MenuName || "");
      setHasChild(initialData.isMenuParent || false);
      setDashboardId(initialData.DashboardId || "");
      setSheetId(initialData.SheetId || "");
    }
  }, [initialData]);

  useEffect(() => {
    if (isMenuParent) {
      setDashboardId("");
      setSheetId("");
    }
  }, [isMenuParent]);

  const handleInputChange = (event, stateSetter) => {
    stateSetter(event.target.value);
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
    console.log(DashboardId);

    if (MenuName === "") {
      toast("Menu Name cannot be empty", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else if (DashboardId === "") {
      toast("Dashboard ID cannot be empty", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else if (SheetId === "") {
      toast("Sheet ID cannot be empty", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else {
      handleSave({ MenuName, isMenuParent, DashboardId, SheetId });
      handleClose();
    }
  };

  return (
    <Dialog open={show} onClose={handleClose}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle>Edit Child Node</DialogTitle>
        <DialogTitle>
          <CloseIcon onClick={handleClose} className="pointerCursor" />
        </DialogTitle>
      </div>
      <DialogContent>
        <form onSubmit={handleEditSubmit}>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label={
              <span>
                Menu
                <RequiredAsterisk>*</RequiredAsterisk>
              </span>
            }
            fullWidth
            variant="outlined"
            type="text"
            value={MenuName}
            // required
            onChange={(e) => handleInputChange(e, setTitle)}
          />

          {!isMenuParent && (
            <>
              <TextField
                margin="dense"
                id="dashboardId"
                label={
                  <span>
                    Dashboard ID
                    <RequiredAsterisk>*</RequiredAsterisk>
                  </span>
                }
                fullWidth
                variant="outlined"
                type="text"
                value={DashboardId}
                onChange={(e) => handleInputChange(e, setDashboardId)}
              />

              <TextField
                margin="dense"
                id="sheetId"
                label={
                  <span>
                    Sheet ID
                    <RequiredAsterisk>*</RequiredAsterisk>
                  </span>
                }
                fullWidth
                variant="outlined"
                type="text"
                value={SheetId}
                onChange={(e) => handleInputChange(e, setSheetId)}
              />
            </>
          )}

          <FormControlLabel
            control={
              <Switch
                checked={isMenuParent}
                onChange={() => setHasChild(!isMenuParent)}
              />
            }
            label="Has a Child"
          />
        </form>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          onClick={handleEditSubmit}
          sx={{ backgroundColor: primaryColor1 }}
        >
          Save
        </Button>
      </DialogActions>
      <ToastContainer />
    </Dialog>
  );
};

export default EditModal2;
