import React, { useState, useEffect } from "react";
import Node from "./Node/Node";
import AddNodeModal from "./Node/AddNodeModal";
import { createNode } from "./Node/TreeUtils";
import { primaryColor1, whiteColor } from "../../commonStyles/styles";
import { Box, Paper } from "@mui/material";

const MenuTree = () => {
  const [menu, setMenu] = useState(
    JSON.parse(localStorage.getItem("menu")) || []
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [MenuName, setMenuName] = useState(
    localStorage.getItem("MenuName") || "Clinigence Analytics"
  );
  const [saveClicked, setSaveClicked] = useState(false);

  const addRootNode = (nodeTitle) => {
    const newNode = createNode(Date.now(), nodeTitle, true, null, null);
    setMenu((prevMenu) => [...prevMenu, newNode]);
    setModalVisible(false);
  };

  const handleSaveClick = () => {
    localStorage.setItem("menu", JSON.stringify(menu));
    setSaveClicked(true);
  };

  useEffect(() => {
    if (saveClicked) {
      setTimeout(() => setSaveClicked(false), 2000);
    }
  }, [saveClicked]);

  return (
    <>
      <Paper elevation={1} sx={{ borderRadius: "5px" }}>
        <Box
          //   sx={{ height: 400, width: "100%" }}
          sx={{
            p: 4,
            alignItems: "center",
            "& .super-app-theme--header": {
              backgroundColor: primaryColor1,
              color: whiteColor,
            },
          }}
        >
          <h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Menu Configuration</b>
          </h3>
          <Node
            MenuId={null}
            MenuName={MenuName}
            DashboardId={null}
            sheetId={null}
            Children={menu}
            isMenuParent={true}
            setMenu={setMenu}
            menu={menu}
            setMenuName={setMenuName}
            menuName={MenuName}
          />
          <AddNodeModal
            isOpen={modalVisible}
            onRequestClose={() => setModalVisible(false)}
            onAddNode={addRootNode}
          />
        </Box>
      </Paper>
    </>
  );
};

export default MenuTree;
