import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Grid,
  Paper,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import {
  itemHover,
  backgroundBlue,
  hoverBlueDark,
  itemHover1,
} from "../commonStyles/styles";
import { useLocation } from "react-router-dom";
import {
  selectBiLogin,
  setBiLogin,
  setBiUserToken,
  currentUserData,
} from "../user/LoginSlice";
import axios from "axios";
import { routeName } from "../commonStrings/CommonStrings";
import Board from "./Board";
import "./analytics.scss";

const ResponsivePaper = ({ children }) => {
  return (
    <Paper
      elevation={1}
      sx={{
        boxShadow: "none",
        borderRadius: 0,
        maxWidth: "100%", // Make menu responsive
      }}
    >
      {children}
    </Paper>
  );
};

const AnalyticsNavbar = ({ items, isInitialized }) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  const [reloadBoard, setReloadBoard] = useState(false);
  const [boardData, setBoardData] = useState("");
  const [dashboardId, setDashboardId] = useState("");
  const [sheetId, setSheetId] = useState("");
  const [customerToken, setCustomerToken] = useState({});
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const dispatch = useDispatch();
  const biUser = useSelector(selectBiLogin);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);

  const biUserData = useSelector(selectBiLogin);
  const userData = useSelector(currentUserData);

  const handleBoardReload = () => {
    setReloadBoard((prevValue) => !prevValue);
  };

  const getDashboardDetails = (dashboardId) => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
      // "AnotherHeader": "Value"
    };

    const loggedInUserName =
      pathnames[0] === routeName ? biUserData?.UserName : userData?.UserName;

    const reqBody = {
      dashboardId: dashboardId,
      userName: loggedInUserName,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/quickSight/dashboard`, reqBody, {
        headers: headers,
      })
      .then((response) => {
        // Handle the response data here

        setBoardData(response.data.data.EmbedUrl);

        // setMenuList(response.data.data.bi);
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error("Error:", error);
      });
  };

  function findMenuByName(menu, targetName) {
    var tempDashboardId = "";
    var tempSheetId = "";

    items.map((e) => {
      if (e.MenuName === menu) {
        e.Children.map((submenu) => {
          if (submenu.MenuName === targetName) {
            console.log(submenu);
            tempDashboardId = submenu.DashboardId;
            tempSheetId = submenu.SheetId;
          }
        });
      }
    });

    return { dashboardId: tempDashboardId, sheetId: tempSheetId };
  }

  useEffect(() => {
    if (pathnames[0] === routeName) {
      const newCustomerToken = biUserData?.CustomerToken;
      setCustomerToken(newCustomerToken);
    }

    const homeMenu = findMenuByName("Summary", "Home");
    setSheetId(homeMenu.sheetId);
    console.log(homeMenu);

    if (pathnames[0] === routeName) {
      if (isInitialized) {
        if (homeMenu !== "") {
          getDashboardDetails(homeMenu.dashboardId);
          // handleBoardReload();
        }
      }
    } else {
      if (homeMenu !== "") {
        getDashboardDetails(homeMenu.dashboardId);
        // handleBoardReload();
      }
    }
    // Call the function to find the "Home" menu under "Summary"

    setBreadcrumbItems(["Summary", "Home"]);
  }, [isInitialized]);

  const [popoverStack, setPopoverStack] = useState([]);
  const [anchorPositions, setAnchorPositions] = useState([]);

  const handleArrowClick = (event, item, breadcrumbSet, index) => {
    if (breadcrumbSet === "case1") {
      setBreadcrumbItems([item.MenuName]);
    }
    // if (item.Children.length !== 0) {

    //   const newPopoverStack = [...popoverStack];
    //   newPopoverStack.push(item.Children);
    //   setPopoverStack(newPopoverStack);

    //   const newAnchorPositions = [...anchorPositions];
    //   const rect = event.currentTarget.getBoundingClientRect();
    //   const rightPosition = rect.right + window.pageXOffset;
    //   const topPosition = rect.top + window.pageYOffset;

    //   // Check if the right position goes beyond the screen width
    //   const screenWidth = window.innerWidth;
    //   const popoverWidth = 250; // Adjust this based on your popover width
    //   const adjustedLeftPosition =
    //     rightPosition + popoverWidth > screenWidth
    //       ? rightPosition - popoverWidth
    //       : rightPosition;

    //   newAnchorPositions.push({
    //     top: topPosition,
    //     left: adjustedLeftPosition,
    //   });

    //   setAnchorPositions(newAnchorPositions);
    //   breadcrumbItems.push(item.MenuName);
    // }
    if (item.Children.length !== 0) {
      const parentRect = event.currentTarget.getBoundingClientRect();
      const parentRightPosition = parentRect.left + window.pageXOffset - 15;
      const parentTopPosition = parentRect.bottom + window.pageYOffset;
      // if (breadcrumbSet === "case1") {
      //   // Position the first submenu popover below the menu item
      //   setAnchorPositions([
      //     { top: parentTopPosition, left: parentRightPosition },
      //   ]);
      // } else {
      //   // Position subsequent popovers to the right of the parent popover
      //   const newAnchorPositions = [...anchorPositions];
      //   const lastAnchorPosition =
      //     newAnchorPositions[newAnchorPositions.length - 1];
      //   const adjustedLeftPosition = lastAnchorPosition.left + 200; // Adjust the offset as needed
      //   newAnchorPositions.push({
      //     top: parentTopPosition,
      //     left: adjustedLeftPosition,
      //   });
      //   setAnchorPositions(newAnchorPositions);
      // }

      const newPopoverStack = [...popoverStack];
      newPopoverStack.push(item.Children);
      setPopoverStack(newPopoverStack);

      const newAnchorPositions = [...anchorPositions];
      const rect = event.currentTarget.getBoundingClientRect();
      const rightPosition = rect.right + window.pageXOffset - 15;
      const topPosition = rect.top + window.pageYOffset;

      // Check if the right position goes beyond the screen width
      const screenWidth = window.innerWidth;
      const popoverWidth = 250; // Adjust this based on your popover width
      const adjustedLeftPosition =
        rightPosition + popoverWidth > screenWidth
          ? rightPosition - popoverWidth
          : rightPosition;

      newAnchorPositions.push({
        top: topPosition,
        left: adjustedLeftPosition,
      });
      if (breadcrumbSet === "case1") {
        // Position the first submenu popover below the menu item
        setAnchorPositions([
          { top: parentTopPosition, left: parentRightPosition },
        ]);
      } else {
        setAnchorPositions(newAnchorPositions);
      }
      breadcrumbItems.push(item.MenuName);
      setSelectedButtonIndex(index);
    } else {
      // setAnchorEl(null);

      setSheetId(item.SheetId);
      setDashboardId(item.DashboardId);
      getDashboardDetails(item.DashboardId);
      handleBoardReload();
      handleClosePopover();
      breadcrumbItems.push(item.MenuName);
      seperateFunction();
      setSelectedButtonIndex(index);
    }
  };

  const seperateFunction = () => {
    console.log("Hello World");
  };
  const handleClosePopover = () => {
    setPopoverStack([]);
    setAnchorPositions([]);
    setSelectedButtonIndex(null);
    // const newPopoverStack = [...popoverStack];
    // newPopoverStack.pop();
    // setPopoverStack(newPopoverStack);

    // const newAnchorPositions = [...anchorPositions];
    // newAnchorPositions.pop();
    // setAnchorPositions(newAnchorPositions);
  };
  return (
    <>
      <AppBar
        position="static"
        elevation={1}
        sx={{ background: "white", color: "black" }}
      >
        <Toolbar
          sx={{
            "@media (min-width: 600px)": {
              minHeight: "24px", // For screen width >= 600px
            },
            backgroundColor: backgroundBlue,
            // "@media (max-width: 599px)": {
            //   minHeight: "48px", // For screen width < 600px
            // },
            // "@media (min-width: 960px)": {
            //   minHeight: "px", // For screen width >= 960px
            // },
          }}
        >
          <Grid container alignItems="center" spacing={2}>
            <Grid item container xs justify="flex-end">
              {items.map((item, index) => (
                <React.Fragment key={index}>
                  <IconButton
                    onClick={(event) =>
                      handleArrowClick(event, item, "case1", index)
                    }
                    color="inherit"
                    aria-controls={`menu-${index}`}
                    aria-haspopup="true"
                    size="small"
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Verdana",
                      // margin: "-6px 0px 0px -12px",
                      borderRadius: 0,
                      paddingRight: "50px",
                      "&:hover": { backgroundColor: itemHover1 },
                      backgroundColor:
                        selectedButtonIndex === index ? itemHover1 : "inherit",
                    }}
                  >
                    {item.MenuName}{" "}
                    {item.Children.length > 0 ? <ArrowDropDown /> : ""}
                  </IconButton>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        </Toolbar>
        {popoverStack.map((popoverContent, index) => (
          <Menu
            key={index}
            open={true}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handleClosePopover}
            // anchorEl={document.body}
            elevation={1}
            PaperComponent={Paper}
            style={{
              position: "absolute",
              top: anchorPositions[index]?.top - 20 || 0, // Decrease the top value
              left: anchorPositions[index]?.left || 0, // Keep the left value as is
            }}
            slotProps={{
              paper: {
                style: {
                  minWidth: 200, // Set the minimum width as needed
                  backgroundColor: itemHover1,
                  borderRadius: 2,
                },
              },
            }}
          >
            {popoverContent.map((subitem, subindex) => (
              <MenuItem
                alignItems="center"
                key={subindex}
                onClick={(event) => handleArrowClick(event, subitem, "case2")}
                sx={{
                  fontWeight: 100,
                  padding: "7px",
                  fontSize: "13px",
                  fontFamily: "Verdana",
                  backgroundColor: itemHover1,
                  "&:hover": { backgroundColor: backgroundBlue },
                }}
              >
                {subitem.MenuName}{" "}
                {subitem.Children.length > 0 ? <ArrowRight /> : ""}
              </MenuItem>
            ))}
          </Menu>
        ))}
      </AppBar>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ paddingLeft: "20px", marginTop: "10px" }}
      >
        {breadcrumbItems.map((item, index) => (
          <React.Fragment key={index}>
            <Link
              color="inherit"
              sx={{
                fontSize: "12px",
                cursor: "pointer",
                marginRight: "0px",
                textDecoration: "none",
              }}
            >
              {item}
            </Link>
            {/* {index < breadcrumbItems.length - 1 && <span>;'</span>} */}
          </React.Fragment>
        ))}
      </Breadcrumbs>
      <div>
        <Box
          sx={
            {
              // width: "95vw",
              // p: 1,
              // backgroundColor: "grey",
            }
          }
        >
          <Paper
            elevation={1}
            //   sx={{ padding: "0.5%", borderRadius: "5px" }}
          >
            {boardData !== "" ? (
              <Board
                boardData={boardData}
                sheetId={sheetId}
                customerToken={customerToken}
              />
            ) : (
              ""
            )}
          </Paper>
        </Box>
      </div>
    </>
  );
};

export default AnalyticsNavbar;
