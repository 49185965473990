export const jsonTransformGroup = (groupArr) => {
  const transformedArray = groupArr.map((group) => {
    return {
      label: group.GroupName, // Set the label property to the user's UserName
      value: group.GroupName, // Set the value property to the user's Email (or any other property you desire)
    };
  });

  return transformedArray;
};
