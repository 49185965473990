import React, { useEffect, useState } from "react";
import { Button, Box, Paper, Typography, TextField, Grid } from "@mui/material";
import { currentUserData, selectBiLogin } from "../user/LoginSlice";
import { primaryColor1, whiteColor } from "../commonStyles/styles";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { routeName } from "../commonStrings/CommonStrings";

function Organization() {
  const [liveUserData, setLiveUserData] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    // emailId: "",
    description: "",
  });
  const userData = useSelector(currentUserData);
  const biUserData = useSelector(selectBiLogin);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  useEffect(() => {
    pathnames[0] === routeName
      ? setFormData({
          name: biUserData?.Organization?.OrganizationName,
          email: biUserData?.Organization?.OrganizationEmail,
          description: biUserData?.Organization?.OrganizationDescription,
        })
      : setFormData({
          name: userData?.Organization?.OrganizationName,
          email: userData?.Organization?.OrganizationEmail,
          description: userData?.Organization?.OrganizationDescription,
        });
  }, [userData, biUserData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleReset = () => {
    setFormData({
      name: "",
      email: "",
      description: "",
    });
  };
  return (
    <Paper elevation={3} sx={{ borderRadius: "20px" }}>
      <Box
        //   sx={{ height: 400, width: "100%" }}
        sx={{
          p: 2,
          alignItems: "center",
          "& .super-app-theme--header": {
            backgroundColor: primaryColor1,
            color: whiteColor,
          },
        }}
      >
        <Typography variant="h6" className="sub-header">
          Organization
        </Typography>
        <div
          className="top-header"
          style={{ display: "flex", alignItems: "center" }}
        >
          <form>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: primaryColor1, // Replace "your_custom_color" with your desired color
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: primaryColor1, // Replace "your_custom_color" with your desired color
                      },
                    },
                  }}
                  size="medium"
                  margin="dense"
                  id="name"
                  label="Name"
                  fullWidth
                  variant="outlined"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: primaryColor1, // Replace "your_custom_color" with your desired color
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: primaryColor1, // Replace "your_custom_color" with your desired color
                      },
                    },
                  }}
                  margin="dense"
                  id="email"
                  label="Email"
                  size="medium"
                  fullWidth
                  variant="outlined"
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={12}>
                <TextField
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: primaryColor1, // Replace "your_custom_color" with your desired color
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: primaryColor1, // Replace "your_custom_color" with your desired color
                      },
                    },
                  }}
                  multiline
                  rows={4}
                  size="medium"
                  margin="dense"
                  id="aws-accesskey"
                  label="Description"
                  fullWidth
                  variant="outlined"
                  type="text"
                  name="description"
                  value={formData.awsAccessKey}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            {/* <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2, mr: 1, backgroundColor: primaryColor1 }}
            >
              Save
            </Button>
            <Button
              type="button"
              variant="contained"
              onClick={handleReset}
              sx={{ mt: 2, backgroundColor: primaryColor1 }}
            >
              Reset
            </Button> */}
          </form>
        </div>
      </Box>
    </Paper>
  );
}

export default Organization;
